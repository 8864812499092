import _ from 'lodash'
import React from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { PLACEHOLDER_HERO_URL } from '../../reducers/offersDirectory'
import { screenSizes } from '../../utils/responsive'
import { FeaturedLabel } from './FeaturedOffer'

const cardWidth = 410
const innerCardHeight = 275
const imgHeight = 214
const cardHeight = 425

const CardsListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto;
  margin-bottom: ${props => props.theme.gutter.triple};

  @media (min-width: ${screenSizes.large}px) {
    width: ${screenSizes.largeMax}px;
  }

  @media (max-width: ${screenSizes.large}px) {
    width: ${screenSizes.largeMin}px;
  }

  @media (max-width: ${screenSizes.maxMobile}px) {
    max-width: 100%;
    justify-content: center;
    padding: 16px;

    & > a {
      div {
        justify-content: center;
      }
    }
  }

  @media (max-width: ${screenSizes.iphone6plus}px) {
    & > a {
      width: 100%;

      div {
        width: 100%;
      }
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${cardWidth}px;
  height: ${cardHeight}px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 32px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

  ${props =>
    props.featured &&
    css`
      @media (max-width: ${screenSizes.maxMobile}px) {
        height: 465px;
      }
    `} &:hover {
    box-shadow: 0 2px 8px rgba(153, 153, 153, 0.4);
    transform: translate(0, -6px);
  }

  @media (max-width: 1318px) {
    margin-bottom: 24px;
  }

  @media (max-width: ${screenSizes.maxMobile}px) {
    &:hover {
      box-shadow: 0 2px 8px rgba(153, 153, 153, 0.4);
      transform: none;
    }
  }
`

const CardHero = styled.div`
  background-image: url(${props => props.heroURL});
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: ${imgHeight}px;
  transition: all 0.3s ease-in-out;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  padding: ${props => props.theme.gutter.triple};
`

const CardHeader = styled.span`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  color: ${props => props.theme.descriptionColor};
  margin-bottom: ${props => props.theme.gutter.standard};
  height: 24px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
`

const CardDetail = styled.span`
  font-size: ${props => props.theme.fontSize.caption};
  color: #565c63;
  margin-bottom: ${props => props.theme.gutter.double};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 15px;
  flex: none;
`

const CardDescription = styled.div`
  font-size: ${props => props.theme.fontSize.button};
  flex: 1;
  height: 55px;
  color: ${props => props.theme.descriptionColor};
  line-height: 1.3;
`

const CallToActionLink = styled.div`
  font-style: normal;
  font-weight: 600;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  color: ${props => props.theme.linkColor};
  font-size: ${props => props.theme.fontSize.button};
`

const FillerCard = styled(CardContainer)`
  opacity: 0;
`

const Featured = styled(FeaturedLabel)`
  font-size: ${props => props.theme.fontSize.small};
  letter-spacing: 0.75px;
  margin-bottom: ${props => props.theme.gutter.triple};

  @media (min-width: ${screenSizes.maxMobile}px) {
    display: none;
  }
`

class ExperienceCard extends React.PureComponent {
  render() {
    const { experience, featured } = this.props
    const heroURL = experience.heroImage.url || PLACEHOLDER_HERO_URL
    const hasImg = !!heroURL

    return (
      <CardContainer featured={featured}>
        <CardHero heroURL={heroURL} />
        <CardBody hasImg={hasImg}>
          {featured && (
            <div>
              <Featured>FEATURED OFFER</Featured>
            </div>
          )}
          <CardHeader data-test="offer-title">{experience.name}</CardHeader>
          <CardDetail data-test="offer-price">{experience.priceDescription}</CardDetail>
          <CardDescription hasImg={hasImg} data-test="offer-description">
            <ResponsiveEllipsis
              text={experience.publicDescriptionText}
              style={{ whiteSpace: 'pre-wrap', height: '100%' }}
              maxLine={2}
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </CardDescription>
          <CallToActionLink data-test="offer-view-detail-btn-link">View Details</CallToActionLink>
        </CardBody>
      </CardContainer>
    )
  }
}

class ExperienceCards extends React.PureComponent {
  constructor(props) {
    super(props)
    this._calcNumberOfFillerCards.bind(this)
  }

  _calcNumberOfFillerCards = (numExpCards, containerWidth) => {
    // includes margin
    const fullCardWidth = cardWidth + 16
    const numCardsPerRow = Math.floor(containerWidth / fullCardWidth)
    const numRows = Math.ceil(numExpCards / numCardsPerRow)

    return numExpCards < numCardsPerRow ? numCardsPerRow - numExpCards : (numCardsPerRow * numRows - numExpCards) % numCardsPerRow
  }

  render() {
    const { experiences, venue, clientId, selectExperience, cardContainerWidth, queryStringVenues, groupVenue } = this.props
    const numberOfFillerCards = this._calcNumberOfFillerCards(experiences.length, cardContainerWidth)

    return (
      <CardsListContainer className="cards-list-container">
        {_.map(experiences, (experience, idx) => {
          const params = new URLSearchParams({})
          if (clientId) {
            params.append('client_id', clientId)
          }
          if (queryStringVenues) {
            params.append('venues', queryStringVenues)
            params.append('group_venue', groupVenue)
          }
          if (experience.tracking) {
            params.append('tracking', experience.tracking)
          }
          const detailedURLKey = `/experiences/${venue.urlKey}/${experience.landingURLKey}?${params.toString()}`
          return (
            <Link
              key={experience.id}
              data-test="sr-experiences-offers-experience-card"
              to={detailedURLKey}
              onClick={() => selectExperience(experience.landingURLKey)}
            >
              <ExperienceCard featured={false} experience={experience} venue={venue} clientId={clientId} />
            </Link>
          )
        })}
        {_.times(numberOfFillerCards, idx => (
          <FillerCard key={`sr-filler-card-${idx}`} />
        ))}
      </CardsListContainer>
    )
  }
}

export default ExperienceCards
