export const stripHtml = html => {
  const temporalDivElement = document.createElement('div')
  temporalDivElement.innerHTML = html
  return temporalDivElement.textContent || temporalDivElement.innerText || ''
}

export const scrollIntoView = selector => {
  const contactElement = document.querySelector(selector)
  contactElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
}
