import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import VenueInfoContainer from 'widget/experiences/containers/VenueInfoContainer'
import { PLACEHOLDER_HERO_URL } from 'widget/experiences/reducers/offersDirectory'
import { screenSizes } from 'widget/experiences/utils/responsive'
import { ButtonDiv } from '../shared/Link'
import { LineEllipses } from '@sevenrooms/core/ui-kit/typography'

const smallDesktop = 1140

const FeaturedOfferContainer = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${screenSizes.maxMobile}px) {
    display: none;
  }
`

const FeaturedImage = styled.img`
  max-width: 70%;
  height: 445px;

  @media (max-width: ${smallDesktop}px) {
    max-width: 60%;
    height: 310px;
  }
`

const OfferDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 48px 48px 36px 48px;
  background: ${props => props.theme.color.lightGrey};

  @media (max-width: ${smallDesktop}px) {
    padding: 24px;
  }
`

const LabelContainer = styled.div`
  margin-bottom: 32px;
`

export const FeaturedLabel = styled.span`
  padding: ${props => props.theme.gutter.standard};
  background: ${props => props.theme.green};
  color: ${props => props.theme.darkGreen};
  font-size: ${props => props.theme.fontSize.button};
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 19px;
  display: inline-block;
`
const Title = styled.p`
  font-style: normal;
  font-weight: 500;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: ${props => props.theme.gutter.double};

  @media (max-width: ${smallDesktop}px) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
  }
`

const Price = styled.p`
  margin-top: 0;
  color: ${props => props.theme.grey};
  font-size: ${props => props.theme.fontSize.button};
  line-height: 19px;
  margin-bottom: ${props => props.theme.gutter.double};
`

const Description = styled.div`
  flex: 1;
  margin-top: 0;
  font-size: ${props => props.theme.fontSize.button};
  line-height: 1.3;
  color: ${props => props.theme.descriptionColor};
  margin-bottom: 32px;

  @media (max-width: ${smallDesktop}px) {
    margin-bottom: 27px;
  }
`

const DescriptionText = styled.span`
  white-space: pre-wrap;
  ${LineEllipses(4)}

  @media (max-width: ${smallDesktop}px) {
    ${LineEllipses(2)}
  }
`

const CustomButton = css`
  width: 100%;
  padding: ${props => props.theme.gutter.double} 0;
  border-radius: 12px;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: ${props => props.theme.fontSize.button};
  align-self: flex-end;
`

const FeaturedOffer = ({ experience, venue, selectExperience, windowWidth, clientId, singleVenue, queryStringVenues, groupVenue }) => {
  const params = new URLSearchParams({})
  if (clientId) {
    params.append('client_id', clientId)
  }
  if (queryStringVenues && !singleVenue) {
    params.append('venues', queryStringVenues)
    params.append('group_venue', groupVenue)
  }
  if (experience.tracking) {
    params.append('tracking', experience.tracking)
  }
  const experienceURLKey = `/experiences/${venue.urlKey}/${experience.landingURLKey}?${params.toString()}`

  return (
    <FeaturedOfferContainer onClick={() => selectExperience(experience.landingURLKey)}>
      <FeaturedImage src={_.get(experience, 'heroImage.url', null) || PLACEHOLDER_HERO_URL} alt="Featured Offer" />
      <OfferDetails>
        <LabelContainer>
          <FeaturedLabel>FEATURED OFFER</FeaturedLabel>
        </LabelContainer>
        <Title>{experience.name}</Title>
        {!singleVenue && <VenueInfoContainer venue={venue} featured />}
        <Price>{experience.priceDescription}</Price>
        <Description>
          <DescriptionText>{experience.publicDescriptionText || ''}</DescriptionText>
        </Description>
        <Link style={{ textDecoration: 'none' }} to={experienceURLKey}>
          <ButtonDiv customButton={CustomButton} data-test="big-view-details-btn">
            VIEW DETAILS
          </ButtonDiv>
        </Link>
      </OfferDetails>
    </FeaturedOfferContainer>
  )
}

export default FeaturedOffer
