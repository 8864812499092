import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { toggleExperienceBookingModal, selectExperience } from 'widget/experiences/actions/booking'
import { updateBaseVenue } from 'widget/experiences/actions/venue'
import FeaturedOffer from 'widget/experiences/components/offers-directory/FeaturedOffer'
import Footer from 'widget/experiences/components/offers-directory/Footer'
import Header from 'widget/experiences/components/offers-directory/Header'
import MultiVenue from 'widget/experiences/components/offers-directory/MultiVenue'
import SingleVenue from 'widget/experiences/components/offers-directory/SingleVenue'
import { pageview } from 'widget/experiences/utils/analytics'
import { decodeQueryString } from '@sevenrooms/core/api'

const OffersDirectoryPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Content = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background: ${props => props.theme.primaryBackGroundColor};
`

const NoOffers = styled.h1`
  text-align: center;
  font-weight: 500;
`

const Divider = styled.div`
  background: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 100%;
`

class OffersDirectoryPage extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      windowWidth: null,
      cardContainerWidth: null,
      reloading: false,
    }

    this.resizeCallback = _.debounce(this.setWindowWidth.bind(this), 1000)
  }

  componentWillMount() {
    this.reloadBaseVenue()
  }

  componentDidMount() {
    this.setWindowWidth()
    window.addEventListener('resize', this.resizeCallback)
    window.dataLayer = window.dataLayer || []
    window.gtag = function () {
      dataLayer.push(arguments)
    }
    gtag('js', new Date())
    pageview()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeCallback)
  }

  setWindowWidth() {
    const cardsListContainer = document.querySelector('.cards-list-container')

    this.setState({
      windowWidth: window.innerWidth,
      cardContainerWidth: cardsListContainer ? cardsListContainer.clientWidth : undefined,
    })
  }

  reloadBaseVenue() {
    const groupVenue = decodeQueryString().group_venue
    if (!_.isEmpty(groupVenue)) {
      if (groupVenue !== this.props.base_venue.urlKey) {
        const venue = this.props.venues.find(venue => venue.urlKey === groupVenue)
        this.props.updateBaseVenue(venue)
      }
    }
  }

  render() {
    const { base_venue, venues, featuredVenue, featuredOffer, selectExperience, clientId } = this.props

    const singleVenue = venues.length === 1
    const queryStringVenues = venues.map(venue => venue.urlKey).join(',')
    const groupVenue = decodeQueryString().group_venue || base_venue.urlKey
    const allExperiences = _.reduce(venues, (accum, venue) => (venue.experiences ? _.concat(accum, venue.experiences) : accum), [])
    return (
      <OffersDirectoryPageContainer>
        <Header wide logo={base_venue.logoURL} />

        {allExperiences.length > 0 ? (
          <Content>
            {!base_venue.disableFeatureBanner ? (
              <FeaturedOffer
                selectExperience={selectExperience}
                venue={featuredVenue}
                experience={featuredOffer}
                windowWidth={this.state.windowWidth}
                clientId={clientId}
                singleVenue={singleVenue}
                queryStringVenues={queryStringVenues}
                groupVenue={groupVenue}
              />
            ) : (
              <Divider />
            )}
            {singleVenue ? (
              <SingleVenue
                venue={venues[0]}
                experiences={venues[0].experiences}
                clientId={clientId}
                selectExperience={selectExperience}
                cardContainerWidth={this.state.cardContainerWidth}
              />
            ) : (
              <MultiVenue
                base_venue={base_venue}
                venues={venues}
                clientId={clientId}
                selectExperience={selectExperience}
                cardContainerWidth={this.state.cardContainerWidth}
                queryStringVenues={queryStringVenues}
                groupVenue={groupVenue}
              />
            )}
          </Content>
        ) : (
          <NoOffers>There are currently no offers available. Check back soon.</NoOffers>
        )}
        <Footer venue={base_venue} />
      </OffersDirectoryPageContainer>
    )
  }
}

const mapStateToProps = state => ({
  base_venue: state.venue,
  venues: state.offersDirectory.venues,
  clientId: state.offersDirectory.clientId,
  featuredOffer: state.offersDirectory.featuredOffer,
  featuredVenue: state.offersDirectory.featuredVenue,
})

const mapDispatchToProps = {
  toggleExperienceBookingModal,
  selectExperience,
  updateBaseVenue,
}

export default connect(mapStateToProps, mapDispatchToProps)(OffersDirectoryPage)
