import React from 'react'
import styled, { css } from 'styled-components'
import { accessibleComponentWrapper } from 'svr/common/A11y'
import { screenSizes } from '../../utils/responsive'
import { scrollIntoView } from '../../utils/utils'

const LinkComponent = props => <Link role="link" tabIndex="0" {...props} />
const AccessibleLinkComponent = accessibleComponentWrapper(LinkComponent, false)

export const Link = styled.a`
  font-size: ${props => props.theme.fontSize.body};
  color: ${props => props.theme.linkColor};
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.5s;
  &:hover {
    opacity: 0.7;
  }
`

const HeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 70px;
  flex-shrink 1;
`

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${screenSizes.desktop}px;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;

  ${props =>
    props.wide &&
    css`
      padding: 0 80px;
      width: 100%;
    `};

  @media (max-width: ${screenSizes.maxDesktop}px) {
    width: 100%;
    padding: 0 ${props => props.theme.gutter.double};
  }
`

const VenueLogo = styled.img`
  height: 32px;
`

const Header = ({ logo, wide = false }) => (
  <HeaderContainer>
    <InnerContainer wide={wide}>
      <div>{logo && <VenueLogo src={logo} aria-label="Venue Logo" alt="Venue Logo" />}</div>
      <div>
        <AccessibleLinkComponent onClick={() => scrollIntoView('#experiences-list-footer')}>Contact Us</AccessibleLinkComponent>
      </div>
    </InnerContainer>
  </HeaderContainer>
)

export default Header
