/* eslint-disable prefer-rest-params */
/* eslint-disable react/prop-types */
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { ExperienceType } from 'mgr/pages/shared/utils/Constants'
import { pageview } from 'widget/experiences/utils/analytics'
import { toggleBookingModal, selectExperience } from '../actions/booking'
import InactiveModal from '../components/single-experience/InactiveModal'
import DefaultTemplate from '../components/template-layouts/Default'
import TemplateTwo from '../components/template-layouts/Template-Two'
import { getReservationsWidgetURL, getExperiencesListURL, getEventsWidgetURL } from '../utils/urls'
import { scrollIntoView } from '../utils/utils'
import { decodeQueryString } from '@sevenrooms/core/api'

const LandingPageContainer = styled.div`
  width: 100%;
  height: 100%;

  font-family: 'Roboto', sans-serif;
`

class LandingPage extends React.PureComponent {
  componentDidMount() {
    window.dataLayer = window.dataLayer || []
    window.gtag = function () {
      dataLayer.push(arguments)
    }
    gtag('js', new Date())
    pageview()
    scrollIntoView('header')
  }

  getLogo(groupVenue) {
    if (!_.isEmpty(groupVenue)) {
      if (groupVenue !== this.props.venue.urlKey) {
        const venue = this.props.venues.find(venue => venue.urlKey === groupVenue)
        return venue.logoURL
      }
    }
    return this.props.venue.logoURL
  }

  renderTemplate() {
    const {
      venue,
      experience,
      toggleBookingModal,
      showBookingModal,
      iframeURL,
      hasMoreExperiences,
      isPublic,
      clientId,
      baseVenueUrlKey,
      queryStringVenues,
    } = this.props
    const groupVenue = decodeQueryString().group_venue
    const logo = this.getLogo(groupVenue)
    switch (experience.templateId) {
      case 'twentytwenty':
        return (
          <TemplateTwo
            venue={venue}
            experience={experience}
            toggleBookingModal={toggleBookingModal}
            showBookingModal={showBookingModal}
            iframeURL={iframeURL}
            hasMoreExperiences={hasMoreExperiences}
            isPublic={isPublic}
            clientId={clientId}
            baseVenueUrlKey={baseVenueUrlKey}
            queryStringVenues={queryStringVenues}
            groupVenue={groupVenue}
            logo={logo}
          />
        )
      default:
        return (
          <DefaultTemplate
            venue={venue}
            experience={experience}
            toggleBookingModal={toggleBookingModal}
            showBookingModal={showBookingModal}
            iframeURL={iframeURL}
            hasMoreExperiences={hasMoreExperiences}
            isPublic={isPublic}
            clientId={clientId}
            baseVenueUrlKey={baseVenueUrlKey}
            queryStringVenues={queryStringVenues}
            groupVenue={groupVenue}
            logo={logo}
          />
        )
    }
  }

  render() {
    const { experience } = this.props

    return (
      <LandingPageContainer variation={experience.templateId}>
        {experience.isInactive && (
          <InactiveModal
            additionalOffers={experience.additionalOffers}
            reservationsWidgetURL={this.props.reservationsWidgetURL}
            experiencesListURL={this.props.experiencesListURL}
          />
        )}
        {this.renderTemplate()}
      </LandingPageContainer>
    )
  }
}

const mapStateToProps = state => {
  const queryString = decodeQueryString()
  const venue = state.experience.selectedVenue
  const experience = state.experience.selectedExperience
  const reservationsWidgetURL = getReservationsWidgetURL(venue.urlKey, experience.landingURLKey, experience.tracking)
  const experiencesListURL = getExperiencesListURL(venue.urlKey)
  const iframeURL =
    experience.offerType === ExperienceType.EVENT
      ? getEventsWidgetURL(venue.urlKey, experience.id, experience.tracking)
      : reservationsWidgetURL
  const clientId = queryString.client_id
  const isPublic = !clientId
  const baseVenueUrlKey = queryString.group_venue || state.venue.urlKey

  return {
    venue,
    venues: state.offersDirectory.venues,
    experience,
    hasMoreExperiences: state.experience.hasMoreExperiences,
    showBookingModal: state.booking.showBookingModal,
    baseVenueUrlKey,
    queryStringVenues: state.offersDirectory.queryStringVenues,
    iframeURL,
    reservationsWidgetURL,
    experiencesListURL,
    isPublic,
    clientId,
  }
}

const mapDispatchToProps = {
  toggleBookingModal,
  selectExperience,
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage)
