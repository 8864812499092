/* eslint-disable react/prop-types */
import { ConnectedRouter } from 'connected-react-router'
import React, { PureComponent } from 'react'
import { Route, Switch } from 'react-router'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import history from 'mgr/pages/shared/utils/History'
import BoneFishLandingPage from './containers/BoneFishLandingPage'
import LandingPage from './containers/LandingPage'
import OffersDirectoryPage from './containers/OffersDirectory'
import { getTheme } from './utils/theme'

const GlobalStyle = createGlobalStyle`
  html, body, #app {
    height: 100%;
    width: 100%;
  }

  html {
    font-family: 'Roboto', sans-serif;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;

  * {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
`

// TODO no relative imports
class App extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <ThemeProvider theme={getTheme(this.props.widgetSettings)}>
        <AppContainer>
          <GlobalStyle />
          <ConnectedRouter history={history}>
            <ContentContainer>
              <Switch>
                <Route path="/experiences/:venue/:landingURLKey" component={LandingPage} />
                <Route path="/experiences/:venue" component={OffersDirectoryPage} />
                <Route
                  path={`/experiences/${this.props.venue.url_key}/waitlist_landing`}
                  render={() => <BoneFishLandingPage widgetSettings={this.props.widgetSettings} />}
                />
                <Route path="/" component={LandingPage} />
                <Route path="*" render={() => <div>No Route Found</div>} />
              </Switch>
            </ContentContainer>
          </ConnectedRouter>
        </AppContainer>
      </ThemeProvider>
    )
  }
}

export default App
