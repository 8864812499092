import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import { NoStyleLink } from 'widget/experiences/components/shared/Link'
import { screenSizes } from 'widget/experiences/utils/responsive'
import { getVenueMapUrl } from 'widget/scripts/utils/googleMapLink'

const VenueInfo = styled.div`
  height: auto;
  @media (max-width: ${screenSizes.maxMobile}px) {
    text-align: center;
  }
`

const VenueName = styled.div`
  min-height: 44px;
  font-size: ${props => props.theme.fontSize.display};
  font-weight: bold;
  line-height: 44px;
  margin-top: 0;
  margin-bottom: 0;
`

const Location = styled.div`
  height: 44px;
  color: ${props => props.theme.navigationDarkRgba60};
  font-weight: 500;
  line-height: 44px;
  font-size: ${props => props.theme.fontSize.subheader};
  margin: 0px 0px ${props => props.theme.gutter.triple} ${props => props.theme.gutter.half};
`

const FeaturedLocation = styled.div`
  height: 19px;
  color: ${props => props.theme.navigationDarkRgba60};
  line-height: 19px;
  font-size: ${props => props.theme.fontSize.button};
  margin: -${props => props.theme.gutter.half} 0px ${props => props.theme.gutter.double} ${props => props.theme.gutter.half};
`

const VenueInfoContainer = ({ venue, featured = false }) => (
  <VenueInfo>
    <NoStyleLink
      href={getVenueMapUrl({
        name: venue.details.name,
        address: venue.details.addressStreet,
        city: venue.details.addressCity,
        state: venue.details.addressState,
        postalCode: venue.details.addressPostalCode,
        gmapsLink: venue.details.gmapsLink,
      })}
      target="_blank"
      rel="noreferrer noopener"
    >
      {featured !== true ? (
        <div>
          <VenueName>{venue.details.name}</VenueName>
          <Location>
            <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '12px' }} />
            {venue.details.addressCity}
          </Location>
        </div>
      ) : (
        <FeaturedLocation>
          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '4px' }} />
          {venue.details.name}
        </FeaturedLocation>
      )}
    </NoStyleLink>
  </VenueInfo>
)
export default VenueInfoContainer
