import React from 'react'
import BookingModal from 'widget/experiences/components/shared/BookingModal'
import { Template, Content, TemplateContainer } from 'widget/experiences/components/shared/Layout'
import Details from 'widget/experiences/components/single-experience/Details'
import Footer from 'widget/experiences/components/single-experience/Footer'
import Header from 'widget/experiences/components/single-experience/Header'
import Hero from 'widget/experiences/components/single-experience/Hero'
import { LimitedContainer } from 'widget/experiences/utils/responsive'

// TODO prop types across the app
// TODO how do we want to calculate the year?
const DefaultLayout = ({
  venue,
  experience,
  toggleBookingModal,
  showBookingModal,
  iframeURL,
  hasMoreExperiences,
  isPublic,
  clientId,
  baseVenueUrlKey,
  queryStringVenues,
  groupVenue,
  logo,
}) => (
  <TemplateContainer>
    <Template>
      <Header
        venue={venue}
        onBookNowClick={toggleBookingModal}
        logo={logo}
        hasMoreExperiences={hasMoreExperiences}
        isPublic={isPublic}
        clientId={clientId}
        baseVenueUrlKey={baseVenueUrlKey}
        queryStringVenues={queryStringVenues}
        groupVenue={groupVenue}
        tracking={experience.tracking}
      />
      <Content>
        <Hero image={experience.heroImage} />
        <LimitedContainer justifyContent="center">
          <Details venue={venue} experience={experience} onBookNowClick={toggleBookingModal} hasMoreExperiences={hasMoreExperiences} />
        </LimitedContainer>
      </Content>
      <Footer venueGroupName={venue.name} />
      <BookingModal toggleBookingModal={toggleBookingModal} show={showBookingModal} iframeURL={iframeURL} />
    </Template>
  </TemplateContainer>
)
export default DefaultLayout
