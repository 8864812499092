import Theme from 'mgr/layout/Theme'

export const getTheme = widgetSettings => ({
  ...Theme,
  bodyFontColor: '#4f4f65',
  primaryBackgroundColor: '#ffffff',
  footerBackGroundColor: '#f4f4f5',
  borderColor: '#d3d3d8',
  buttonBackgroundColor: widgetSettings.color_button,
  linkColor: widgetSettings.color_button,
  subheaderTextColor: '#000000',
  menuLinkColor: widgetSettings.color_button,
  green: '#eafcde',
  darkGreen: '#4e951d',
  grey: '#979a9d',
  descriptionColor: '#1e252f',
})
