import _ from 'lodash'
import queryString from 'query-string'

export const getReservationsWidgetURL = (venueURLKey, landingURLKey, trackingSlug) => {
  let reservationsWidgetURL = `/reservations/${venueURLKey}`
  if (trackingSlug) {
    reservationsWidgetURL += `/${trackingSlug}`
  }
  let params = ''
  if (landingURLKey) {
    params += `experience_id=${landingURLKey}`
  }
  // Pass through all params (e.g. client_id, _ct, etc)
  const queryStr = _.trimStart(window.location.search, '?')
  if (queryStr) {
    const queryStringParams = queryString.parse(queryStr)
    delete queryStringParams.venues
    params = [queryString.stringify(queryStringParams), params].join('&')
  }
  return [reservationsWidgetURL, params].join('?')
}

export const getExperiencesListURL = venueURLKey => {
  const experiencesListURL = `/experiences/${venueURLKey}`
  // Pass through all params (e.g. client_id, _ct, etc)
  const queryStr = _.trimStart(window.location.search, '?')
  return [experiencesListURL, queryStr].join('?')
}

export const getEventsWidgetURL = (venueURLKey, experienceId, trackingSlug) => {
  const params = new URLSearchParams({ experience_id: experienceId })
  if (trackingSlug) {
    params.append('tracking', trackingSlug)
  }
  return `/events/${venueURLKey}?${params.toString()}`
}
