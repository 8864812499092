import * as AnalyticsEvents from 'widget/dining/utils/analyticsEvents'
import * as ActionTypes from '../actions/ActionTypes'

const initialState = {
  showBookingModal: false,
  experienceModalId: null,
}

const bookingReducer = (state = initialState, action) => {
  const offerHandler = new AnalyticsEvents.OfferAnalytics()

  switch (action.type) {
    case ActionTypes.TOGGLE_EXPERIENCE_BOOK:
      offerHandler.bookOfferClick()
      return {
        ...state,
        showBookingModal: !state.showBookingModal,
      }
    case ActionTypes.TOGGLE_EXPERIENCE_MODAL:
      return {
        ...state,
        experienceModalId: action.experienceId,
      }
    default:
      return state
  }
}

export default bookingReducer
