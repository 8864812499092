/* global PRELOADED */

import { routerMiddleware } from 'connected-react-router'
import React from 'react' // Required for ReactDOM despite being unused
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import { enableBatching } from 'redux-batched-actions'
import thunk from 'redux-thunk'
import history from 'mgr/pages/shared/utils/History'
import main from 'widget/scripts/main.js'
import App from './App'
import rootReducer from './reducers'
import { getInitialExperienceState } from './reducers/experiences'
import { getInitialOffersDirectoryState } from './reducers/offersDirectory'
import { getInitialVenueState } from './reducers/venue'

main('PROD')

const store = createStore(
  enableBatching(rootReducer),
  {
    venue: {
      ...getInitialVenueState(PRELOADED.base_venue),
    },
    experience: getInitialExperienceState(PRELOADED.main_venue, PRELOADED.main_experience, PRELOADED.venues),
    offersDirectory: {
      ...getInitialOffersDirectoryState(PRELOADED.client_id, PRELOADED.venues),
    },
  },
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)(applyMiddleware(thunk), applyMiddleware(routerMiddleware(history)))
)

if (document.getElementById('app')) {
  ReactDOM.render(
    <Provider store={store}>
      <AppContainer>
        <App widgetSettings={PRELOADED.widget_settings} venue={PRELOADED.base_venue} />
      </AppContainer>
    </Provider>,
    document.getElementById('app')
  )
}

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./App', () => {
    render(App, globalInit)
  })
}
