import React from 'react'
import styled, { css } from 'styled-components'
import SevenroomsLogo from 'svr/common/SevenroomsLogo'
import { screenSizes, LimitedContainer } from '../../utils/responsive'

const FooterContainer = styled.footer`
  display: flex;
  width: 100%;
  background: ${props => props.theme.footerBackGroundColor};
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.gutter.triple};
  color: ${props => props.theme.bodyFontColor};
  flex: 0 0 auto;
  z-index: -1;

  ${props =>
    props.variation === '2020' &&
    css`
      border-top: #4b4e63 5px solid;
    `};
`

const InnerContainer = styled(LimitedContainer)`
  background: ${props => props.theme.footerBackGroundColor};

  @media (max-width: ${screenSizes.maxDesktop}px) {
    justify-content: space-between;
    flex-direction: row;
  }

  @media (max-width: ${screenSizes.maxDetails}px) {
    width: 100%;
    justify-content: center;
    padding: ${props => props.theme.gutter.double};
    background: transparent;
    text-align: center;
    flex-direction: column;
  }
`

const FooterDetail = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (max-width: ${screenSizes.maxDetails}px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    padding: ${props => props.theme.gutter.standard};
  }
`

const PoweredBy = styled.span`
  font-size: ${props => props.theme.fontSize.xsmall};
  margin-right: ${props => props.theme.gutter.standard};
  color: black;
`

const Footer = ({ venueGroupName, variation }) => (
  <FooterContainer variation={variation}>
    <InnerContainer>
      <FooterDetail>
        &copy; {new Date().getFullYear()} {venueGroupName}
      </FooterDetail>
      <FooterDetail>
        <PoweredBy>powered by</PoweredBy>
        <SevenroomsLogo />
      </FooterDetail>
    </InnerContainer>
  </FooterContainer>
)

export default Footer
