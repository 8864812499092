import _ from 'lodash'
import { camelCaseObject } from 'svr/common/ObjectUtils'
import { decodeQueryString } from '@sevenrooms/core/api'
import * as ActionTypes from '../actions/ActionTypes'
import { convertVenue } from '../utils/venue'

export const PLACEHOLDER_HERO_URL =
  'https://www.sevenrooms.com/.h/download/AMIfv97Tah5DQak1U6DKzpaGKRqqDhPeeBoVZThdumaElMmew8TYtEDWhbOEd7OZRAAb31_B4eqNqTbBrLMfB77RIC1ZAC7RpvxAcgPypQN8cHAQY2LYgmz2JVes5GWMX7_Ul_Dwda6G9W3AFgnEk3G4dxHWiTnferkk-0NvmWtW4HPfnWJ0ps38x_Dgc5rV7Ux4-DbnlcIljWPlDVK3q3rlqkmVq856iR_0ygyucmDGvZznSazaDDfEsy-1n1Hpf2PMjGFhlcJmUVu_LCDoOb_pR8zKeFVwI1ZRgu-289lW8JWvJ1yaGns'

export const URL_BASE = '/.h/download'
const IMAGE_SIZES = ['small', 'medium', 'large', 'mega']
const IMAGE_WIDTHS = {
  small: 100,
  medium: 300,
  large: 1000,
  mega: 2000,
}
const getSrcSet = photoDict => {
  if (!photoDict) {
    return ''
  }

  return IMAGE_SIZES.reduce((srcSet, size) => {
    const widthProp = `${size}_width`

    if (photoDict[size]) {
      if (photoDict[widthProp]) {
        return `${srcSet}${URL_BASE}/${photoDict[size]} ${photoDict[widthProp]}w, `
      }
      return `${srcSet}${URL_BASE}/${photoDict[size]} ${IMAGE_WIDTHS[size]}w, `
    }

    return srcSet
  }, '')
}

export const transformExperience = experience => {
  const heroKey = _.get(experience, ['hero_image', 'photo_dict', 'large'])
  const heroImage = {
    url: (heroKey && (heroKey.includes('/static/') ? heroKey : `/.h/download/${heroKey}`)) || PLACEHOLDER_HERO_URL,
    photoDict: (experience.hero_image && experience.hero_image.photo_dict) || {},
    srcSet: experience.hero_image && getSrcSet(experience.hero_image.photo_dict),
  }
  const menuURL = experience.menu_blob_id && `/.h/download/${experience.menu_blob_id}`
  const additionalImages =
    experience.image_list && experience.image_list.length > 0
      ? experience.image_list.map(img => ({
          default: `${URL_BASE}/${img.photo_dict.large}`,
          srcSet: getSrcSet(img.photo_dict),
          photoDict: img.photo_dict || {},
        }))
      : []

  const landingURLKey = experience.landing_url_key

  return {
    ...camelCaseObject(_.omit(experience, 'landing_url_key')),
    landingURLKey,
    heroImage,
    menuURL,
    additionalImages,
    offerType: experience.offer_type,
    tracking: new URLSearchParams(window.location.search).get('tracking'),
  }
}

export const getInitialOffersDirectoryState = (clientId, venuesRaw) => {
  venuesRaw.forEach(
    item =>
      // eslint-disable-next-line no-param-reassign
      (item.experiences = _.map(item.experiences, experience => transformExperience(experience)).sort((a, b) => a.sortOrder - b.sortOrder))
  )

  const allVenues = _.map(venuesRaw, venue => convertVenue(venue))
  const venues = _.filter(allVenues, venue => !_.isEmpty(venue.experiences))
  let featuredOffer = venues.map(venue => venue.experiences.find(experience => experience.isFeatured)).find(i => i)
  let featuredVenue = _.sample(venues) || null

  if (featuredOffer) {
    featuredVenue = venues.find(i => featuredOffer?.venueId === i.key)
  } else {
    featuredOffer = featuredVenue ? _.sample(featuredVenue.experiences) : null
  }
  const selectedExperienceID = null
  const queryStringVenues = decodeQueryString().venues

  return {
    venues: allVenues,
    featuredVenue,
    featuredOffer,
    clientId,
    selectedExperienceID,
    queryStringVenues,
  }
}

const offersDirectoryReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_EXPERIENCE_MODAL:
      return { ...state, selectedExperienceID: action.experienceId }
    default:
      return state
  }
}

export default offersDirectoryReducer
