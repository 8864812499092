import React from 'react'
import styled from 'styled-components'
import { screenSizes } from '../../utils/responsive'

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 40px 32px 11px;
  text-align: center;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.lightGrey};
  border-bottom-width: ${props => (props.showDividerLine === true ? 1 : 0)}px;
  margin-bottom: 38px;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SectionHeader = styled.h1`
  font-style: normal;
  font-weight: 200;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  font-size: ${props => props.theme.fontSize.display};
  margin: 0 0 10px 0;
`

const NumOffers = styled.div`
  color: ${props => props.theme.color.grey};
  font-size: ${props => props.theme.fontSize.body};
  text-align: center;
  margin-bottom: ${props => props.theme.gutter.double};

  @media (min-width: ${screenSizes.maxMobile + 1}px) {
    display: none;
  }
`

const MidSection = ({ headerText, numOffers, showDividerLine = false }, wide = false) => (
  <SectionContainer showDividerLine={showDividerLine}>
    <InnerContainer wide={wide}>
      <SectionHeader>{headerText}</SectionHeader>
      <NumOffers>{numOffers} Experiences Available</NumOffers>
    </InnerContainer>
  </SectionContainer>
)

export default MidSection
