export const GA_TRACKING_ID = 'UA-25625875-2'

export const pageview = () => {
  window.gtag('config', GA_TRACKING_ID, {
    page_location: window.location.href,
  })
}

export const event = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  })
}
