/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { screenSizes } from '../utils/responsive'
import { getReservationsWidgetURL } from '../utils/urls'

const LandingPageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  font-family: 'Roboto', sans-serif;
`

const LandingPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 450px;
  position: relative;

  @media (max-width: 658px) {
    width: 100%;
  }
`

const LandingPageBackgroundImage = styled.div`
  display: block;
  position: relative;
  background: url(${props => props.mediaUrl}images/bonefishbg.png) no-repeat;
  background-size: contain;
  width: 100%;
  height: 0;
  padding-top: 178.84%;
`

const WaitlistButton = styled.div`
  border-radius: 8px;
  background-color: #5c381f;
  font-family: OpenSans, sans-serif;
  color: #fff;
  text-align: center;
  padding: 16px 0;
  width: 100%;
  transition: filter 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(120%);
  }
`

const WaitlistLink = styled.a`
  position: absolute;
  left: 0;
  top: 67%;
  text-decoration: none;
  width: 100%;
  padding: 5%;

  @media (max-width: ${screenSizes.tiny}px) {
    top: 68%;
  }

  @media (max-width: ${screenSizes.iphone6}px) {
    top: 65%;
  }

  @media (max-width: ${screenSizes.xtiny}px) {
    top: 63%;
  }

  @media (max-height: 824px) {
    top: 66%;
  }

  @media (max-height: 568px) {
    top: 63%;
  }
`

const WailistJoinText = styled.div`
  font-size: 20px;
  letter-spacing: 2.5px;
  margin-bottom: 8px;

  @media (max-width: ${screenSizes.tiny}px) {
    font-size: 16px;
  }
`

const WaitlistTimes = styled.div`
  font-size: 16px;
`

class BoneFishLandingPage extends React.PureComponent {
  render() {
    const { waitlistWidgetURL } = this.props
    return (
      <LandingPageContainer>
        <LandingPageWrapper>
          <LandingPageBackgroundImage mediaUrl={this.props.widgetSettings.media_url}>
            <WaitlistLink href={`${waitlistWidgetURL}`} target="_blank">
              <WaitlistButton>
                <WailistJoinText>JOIN WAITLIST</WailistJoinText>
                <WaitlistTimes>15-70 min</WaitlistTimes>
              </WaitlistButton>
            </WaitlistLink>
          </LandingPageBackgroundImage>
        </LandingPageWrapper>
      </LandingPageContainer>
    )
  }
}

const mapStateToProps = state => {
  const reservationsWidgetURL = getReservationsWidgetURL(['client_id'], false)
  const waitlistWidgetURL = `${reservationsWidgetURL}?is_waitlist=true`
  return {
    waitlistWidgetURL,
  }
}

export default connect(mapStateToProps)(BoneFishLandingPage)
