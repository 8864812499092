import React from 'react'
import styled from 'styled-components'
import ExperienceCards from './ExperienceCards'
import MidSection from './MidSection'

export const InnerContent = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background: ${props => props.theme.primaryBackGroundColor};
`

const SingleVenue = ({ experiences, venue, clientId, selectExperience, cardContainerWidth }) => {
  const headerText = 'Come join us at '.concat(venue.name)
  return (
    <InnerContent>
      <MidSection numOffers={experiences.length} headerText={headerText} />
      <ExperienceCards
        venue={venue}
        experiences={experiences}
        clientId={clientId}
        selectExperience={selectExperience}
        cardContainerWidth={cardContainerWidth}
      />
    </InnerContent>
  )
}

export default SingleVenue
