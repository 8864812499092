export const convertVenue = (venue = {}) => ({
  key: venue.id || '',
  name: venue.name || '',
  logoURL: venue.logo_url,
  urlKey: venue.url_key,
  trackingSlug: venue.tracking_slug,
  experiences: venue.experiences || '',
  disableFeatureBanner: venue.disable_feature_banner || '',
  details: {
    name: venue.name || '',
    addressStreet: venue.address || '',
    addressCity: venue.city || '',
    addressState: venue.state || '',
    addressPostalCode: venue.postal_code || '',
    gmapsLink: venue.gmaps_link || '',
    addressCityState: venue.city_state || '',
    phone: venue.phone_number || '',
    email: venue.email || '',
    website: venue.website || '',
    instagramLink: venue.instagram_link || '',
    facebookLink: venue.facebook_link || '',
    twitterLink: venue.twitter_link || '',
  },
})
