import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { accessibleComponentWrapper } from 'svr/common/A11y'
import { screenSizes } from '../../utils/responsive'
import { scrollIntoView } from '../../utils/utils'
import { Link } from '../shared/Link'

const LinkComponent = props => <Link role="link" tabIndex="0" {...props} />
const AccessibleLinkComponent = accessibleComponentWrapper(LinkComponent, false)

const HeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: ${props => props.theme.gutter.double} 0;
`

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${screenSizes.desktop}px;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;

  ${props =>
    props.wide &&
    css`
      width: 100%;
      padding: 0 ${props => props.theme.gutter.double};
    `}

  @media (max-width: ${screenSizes.maxDesktop}px) {
    width: 100%;
    padding: ${props => props.theme.gutter.double};
  }

  @media (max-width: ${screenSizes.maxDetails}px) {
    flex-direction: column;
    margin-bottom: ${props => props.theme.gutter.double};
    justify-content: center;
  }
`

const VenueLogo = styled.img`
  height: 32px;

  @media (max-width: ${screenSizes.maxDetails}px) {
    margin-bottom: ${props => props.theme.gutter.double};
  }
`

const StyledLink = styled(ReactRouterLink)`
  font-size: ${props => props.theme.fontSize.body};
  color: ${props => props.theme.linkColor};
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.5s;

  &:hover {
    opacity: 0.7;
  }

  ${props => props.mediumText && 'font-size: 16px;'} ${props => props.marginRight && `margin-right: ${props.theme.gutter.double};`};

  @media (max-width: ${screenSizes.tiny}px) {
    font-size: 14px;
  }
`

const Header = ({
  logo,
  onBookNowClick,
  wide = false,
  hasMoreExperiences = false,
  isPublic,
  clientId,
  baseVenueUrlKey,
  queryStringVenues,
  groupVenue,
  tracking,
}) => {
  const params = new URLSearchParams({})
  if (clientId) {
    params.append('client_id', clientId)
  }
  if (queryStringVenues) {
    params.append('venues', queryStringVenues)
    params.append('group_venue', groupVenue)
  }
  if (tracking) {
    params.append('tracking', tracking)
  }
  const moreOffersURLKey = `/experiences/${baseVenueUrlKey}?${params.toString()}`
  return (
    <HeaderContainer>
      <InnerContainer wide={wide}>
        <VenueLogo src={logo} aria-label="Venue Logo" alt="" />
        <div>
          {hasMoreExperiences && isPublic && (
            <StyledLink marginRight mediumText={wide} to={moreOffersURLKey}>
              More Offers
            </StyledLink>
          )}
          <AccessibleLinkComponent mediumText={wide} onClick={onBookNowClick} marginRight>
            Reservations
          </AccessibleLinkComponent>
          <AccessibleLinkComponent mediumText={wide} onClick={() => scrollIntoView('.venue-details')}>
            Contact Us
          </AccessibleLinkComponent>
        </div>
      </InnerContainer>
    </HeaderContainer>
  )
}

export default Header
