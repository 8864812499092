import React from 'react'
import { SocialIcon } from 'react-social-icons'
import styled, { css } from 'styled-components'
import { screenSizes } from '../../utils/responsive'
import { Detail } from '../shared/Link'

const SocialIcons = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: ${props => props.theme.gutter.double};
  }

  @media (max-width: ${screenSizes.desktop}px) {
    justify-content: center;
  }
`

const SocialContainer = styled.div`
  padding: ${props => props.theme.gutter.double};

  ${props =>
    props.withBorder &&
    css`
      border-bottom: 1px solid ${props => props.theme.borderColor};
      margin-bottom: ${props => props.theme.gutter.triple};
    `};
`

const InnerContainer = styled.div`
  @media (max-width: ${screenSizes.desktop}px) {
    text-align: center;
  }
`

// TODO if we ever want to add more social types, make this dynamic and just
// pass an array of links, the SocialIcon library can handle it
const Social = ({ venue, label, withBorder = false, variation }) => {
  const { instagramLink, facebookLink, twitterLink } = venue.details
  const hasSocial = instagramLink || facebookLink || twitterLink

  return (
    <SocialContainer withBorder={withBorder}>
      {hasSocial && (
        <InnerContainer>
          {label && (
            <Detail smallText={variation === '2020'} color={variation === '2020' && 'black'} bold={variation !== '2020'}>
              {label}
            </Detail>
          )}
          <SocialIcons>
            {instagramLink && <SocialIcon target="_blank" url={instagramLink} aria-label="instagram" />}
            {facebookLink && <SocialIcon target="_blank" url={facebookLink} aria-label="facebook" />}
            {twitterLink && <SocialIcon target="_blank" url={twitterLink} aria-label="twitter" />}
          </SocialIcons>
        </InnerContainer>
      )}
    </SocialContainer>
  )
}

export default Social
