import { UPDATE_BASE_VENUE } from 'widget/experiences/actions/ActionTypes'
import { convertVenue } from '../utils/venue'

export const getInitialVenueState = (venue = {}) => convertVenue(venue)

const venueReducer = (state = getInitialVenueState(), action) => {
  switch (action.type) {
    case UPDATE_BASE_VENUE:
      return action.venue
    default:
      return state
  }
}

export default venueReducer
