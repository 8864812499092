export function getVenueMapUrl(venueInfo) {
  const { venueName, venueLocation, venueAddress, googleMapsURL } = getUrlData(venueInfo)
  return venueInfo.gmapsLink ? `${venueInfo.gmapsLink}` : `${googleMapsURL}search/${venueName} ${venueAddress} ${venueLocation}`
}

export function getEmbedUrl(venueInfo) {
  const { venueName, venueLocation, venueAddress, key, googleMapsURL } = getUrlData(venueInfo)
  return venueInfo.gmapsLink
    ? `${venueInfo.gmapsLink}&key=${key}`
    : `${googleMapsURL}embed/v1/place?key=${key}&q=${venueName} ${venueAddress} ${venueLocation}`
}

export function getDirectionsUrl(venueInfo) {
  const { venueLocation, venueAddress, googleMapsURL } = getUrlData(venueInfo)
  return `${googleMapsURL}dir//${venueAddress} ${venueLocation}`
}

function getUrlData(venueInfo) {
  const strNotNull = str => str || ''
  const venueName = strNotNull(venueInfo.name)
  const venueCity = strNotNull(venueInfo.city)
  const venueState = strNotNull(venueInfo.state)
  const venuePostalCode = strNotNull(venueInfo.postalCode)
  const venueLocation = `${venueCity}${venueCity && venueState ? ', ' : ''}${venueState}${
    (venueCity || venueState) && venuePostalCode ? ' ' : ''
  }${venuePostalCode}`
  const venueAddress = strNotNull(venueInfo.address)
  const key = 'AIzaSyDP4KTsiA2CR5z0XU4KJizkccAhkrbg4UQ'
  const googleMapsURL = 'https://www.google.com/maps/'
  return { venueName, venueLocation, venueAddress, key, googleMapsURL }
}
