import React from 'react'
import styled, { css } from 'styled-components'
import { screenSizes } from '../../utils/responsive'

export const Link = styled.a`
  font-size: ${props => props.theme.fontSize.body};
  color: ${props => props.theme.linkColor};
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.5s;

  &:hover {
    opacity: 0.7;
  }

  ${props => props.mediumText && 'font-size: 16px;'} ${props => props.marginRight && `margin-right: ${props.theme.gutter.double};`};

  @media (max-width: ${screenSizes.tiny}px) {
    font-size: 14px;
  }
`

export const MenuLink = styled(Link)`
  font-size: ${props => props.theme.fontSize.subheader};
  font-weight: 300;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-decoration: none;
  color: ${props => props.theme.menuLinkColor};
`
export const Button = styled.button`
  padding: ${props => props.theme.gutter.standard} 100px;
  border: none;
  color: ${props => props.theme.color.white};
  background: ${props => props.theme.buttonBackgroundColor};
  cursor: pointer;
  transition: 0.3s opacity;
  font-size: ${props => props.theme.fontSize.subheader};
  border-radius: 3px;
  font-weight: 300;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 1;
  }

  ${props => props.customButton};
`

export const ButtonDiv = styled.div`
  padding: ${props => props.theme.gutter.standard} 100px;
  border: none;
  color: ${props => props.theme.color.white};
  background: ${props => props.theme.buttonBackgroundColor};
  cursor: pointer;
  transition: 0.3s opacity;
  font-size: ${props => props.theme.fontSize.subheader};
  border-radius: 3px;
  font-weight: 300;
  text-align: center;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 1;
  }
  ${props => props.customButton};
`

// todo move this
export const Detail = styled.div`
  font-size: ${props => props.theme.fontSize.medium};
  margin-bottom: ${props => props.theme.gutter.standard};

  ${props => props.extraMargin && `margin-bottom: ${props.theme.gutter.double};`}
  ${props => props.bold && 'font-weight: bold;'}
  ${props => props.semibold && 'font-weight: 500;'}
  ${props =>
    props.smallText &&
    css`
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
    `}
  ${props => props.color && `color: ${props.color};`}
  ${props => props.reset && 'p { margin: 0; }'}
`

export const Icon = styled.a`
  display: flex;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: grey;
  color: white;
  transition: opacity 0.3s;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  svg {
    width: 24px !important;
    height: 24px !important;
  }
`

export const NoStyleLink = styled.a`
  color: inherit;
  text-decoration: none;
  display: inline-block;
`
