import React from 'react'
import styled from 'styled-components'

const SVGContainer = styled.div`
  svg {
    display: block;
    height: 30px;
    width: 110px;

    .primary {
      fill: black;
    }

    .teal {
      fill: ${props => props.theme.color.teal};
    }
  }
`

const SevenroomsLogo = () => (
  <SVGContainer>
    <svg x="0px" y="0px" viewBox="0 0 891.3 89.9" alt="SevenRooms" aria-labelledby="sevenrooms-footer-logo" role="img">
      <title id="sevenrooms-footer-logo">SevenRooms</title>
      <path
        className="primary"
        d="M39,37.7c-10.4-3.2-19.4-5.9-19.4-14.5c0-7.1,8.3-9.6,16-9.6c7.5,0,15.3,2.3,21.7,6.5c0.5,0.3,1.4,0.4,2,0.3 c0.6-0.2,1.1-0.5,1.4-1.1l4.3-8.6c0.4-0.9,0.1-2.1-0.7-2.6C56.5,3,46.1,0,36.1,0C17.3,0,4.7,9.6,4.7,23.9c0,17.9,14.9,22.3,28,26.3 c10.6,3.2,19.7,5.9,19.7,14.9c0,7-6.7,11.1-17.8,11.1c-9.2,0-19.5-4.3-26.8-11.3c-0.5-0.4-1.1-0.7-1.8-0.5 c-0.7,0.1-1.2,0.5-1.5,1.1l-4.2,8.4c-0.4,0.8-0.3,1.8,0.4,2.5c8.9,8.5,21.4,13.5,33.8,13.5c19.6,0,32.8-10.2,32.8-25.3 C67.3,46.3,51.6,41.6,39,37.7"
      />
      <path
        className="primary"
        d="M863.1,37.7c-10.4-3.2-19.4-5.9-19.4-14.5c0-7.1,8.3-9.6,16-9.6c7.5,0,15.3,2.3,21.7,6.5 c0.5,0.3,1.4,0.4,2,0.3c0.6-0.2,1.1-0.5,1.4-1.1l4.3-8.6c0.4-0.9,0.1-2.1-0.7-2.6C880.6,3,870.2,0,860.2,0 c-18.8,0-31.5,9.6-31.5,23.9c0,17.9,14.9,22.3,28,26.3c10.6,3.2,19.7,5.9,19.7,14.9c0,7-6.7,11.1-17.8,11.1 c-9.2,0-19.5-4.3-26.8-11.3c-0.5-0.4-1.1-0.7-1.8-0.5c-0.6,0.1-1.2,0.5-1.5,1.1l-4.2,8.4c-0.4,0.8-0.3,1.8,0.4,2.5 c8.9,8.5,21.4,13.5,33.8,13.5c19.6,0,32.8-10.2,32.8-25.3C891.4,46.3,875.7,41.6,863.1,37.7"
      />
      <path
        className="primary"
        d="M413,1.3h-9.5c-1.1,0-2.1,0.9-2.1,2.1v62.1L354.8,2.1c-0.4-0.5-1-0.8-1.7-0.8h-10c-1.1,0-2.1,0.9-2.1,2.1v84.1 c0,1.1,0.9,2.1,2.1,2.1h9.6c1.1,0,2.1-0.9,2.1-2.1V25.5l46.7,63.2c0.4,0.5,1,0.8,1.7,0.8h9.8c1.1,0,2.1-0.9,2.1-2.1V3.4 C415.1,2.2,414.2,1.3,413,1.3"
      />
      <path
        className="primary"
        d="M560.1,76.8c-17.6,0-32-14.3-32-31.9c0-17.5,14.3-31.7,32-31.7c17.5,0,31.7,14.2,31.7,31.7 C591.8,62.5,577.6,76.8,560.1,76.8 M560.1,0c-25.7,0-45.8,19.7-45.8,44.8c0,25.2,20.1,45,45.8,45c25.6,0,45.7-19.8,45.7-45 C605.8,19.7,585.7,0,560.1,0"
      />
      <path
        className="primary"
        d="M661.3,76.8c-17.6,0-32-14.3-32-31.9c0-17.5,14.3-31.7,32-31.7c17.5,0,31.7,14.2,31.7,31.7 C693,62.5,678.8,76.8,661.3,76.8 M661.3,0c-25.7,0-45.8,19.7-45.8,44.8c0,25.2,20.1,45,45.8,45c25.6,0,45.6-19.8,45.6-45 C706.9,19.7,686.9,0,661.3,0"
      />
      <path
        className="primary"
        d="M467.5,49.8h-20.3V14.3h20.3c10,0,21.8,3,21.8,17.5C489.3,46.7,477.5,49.8,467.5,49.8 M484.9,59.8 c11.5-4.7,17.8-14.6,17.8-28.2c0-19-13.2-30.3-35.2-30.3h-31.9c-1.1,0-2.1,0.9-2.1,2.1v84.1c0,1.1,0.9,2.1,2.1,2.1h9.6 c1.1,0,2.1-0.9,2.1-2.1V62.8h20.3c1.5,0,2.9-0.1,4.3-0.2l16.5,25.2c0.7,1,1.6,1.6,2.7,1.6c0,0,11.4,0,11.4,0c0.5-0.1,1-0.3,1.2-0.8 c0.3-0.5,0.3-1.1,0-1.7L484.9,59.8z"
      />
      <path
        className="primary"
        d="M144.4,76.5H97.8V51.3h40.3c1.1,0,2.1-0.9,2.1-2.1v-8.8c0-1.1-0.9-2.1-2.1-2.1H97.8V14.3h39.7 c1.1,0,2.1-0.6,2.6-1.6c0,0,4.5-8.9,4.5-8.9c0.3-0.5,0.3-1.1,0-1.7c-0.3-0.5-0.9-0.8-1.4-0.8l-57,0c-1.1,0-2.1,0.9-2.1,2.1v84.1 c0,1.1,0.9,2.1,2.1,2.1h58.3c1.1,0,2.1-0.9,2.1-2.1l0-8.9C146.5,77.4,145.6,76.5,144.4,76.5"
      />
      <path
        className="primary"
        d="M321.8,76.5h-46.6V51.3h40.3c1.1,0,2.1-0.9,2.1-2.1v-8.8c0-1.1-0.9-2.1-2.1-2.1h-40.3V14.3h39.7 c1.1,0,2.1-0.6,2.6-1.6c0,0,4.5-8.9,4.5-8.9c0.3-0.5,0.3-1.1,0-1.7c-0.3-0.5-0.9-0.8-1.5-0.8l-57,0c-1.1,0-2.1,0.9-2.1,2.1v84.1 c0,1.1,0.9,2.1,2.1,2.1h58.3c1.1,0,2.1-0.9,2.1-2.1v-8.9C323.9,77.4,323,76.5,321.8,76.5"
      />
      <path
        className="teal mobile"
        d="M248.5,1.3h-23.1h-0.3c-1.1,0-2.1,0.6-2.6,1.6l-4.5,8.9c-0.3,0.5-0.3,1.2,0,1.7c0.3,0.5,0.9,0.8,1.5,0.8h10.7 l-11.7,22.7c-0.3,0.5-0.3,1.2,0,1.7c0.2,0.4,0.6,0.7,1.1,0.8c0.1,0,0.4,0.1,0.4,0.1h10h0c1.1,0,2.1-0.6,2.6-1.6L250,3.8 c0.3-0.5,0.3-1.2,0-1.7C249.6,1.6,249.1,1.3,248.5,1.3"
      />
      <path
        className="primary mobile"
        d="M223.7,50.3h-0.1h-10c-1.1,0-2.1,0.6-2.6,1.6l-10.3,20.3l-29-57.9h31.6c1.1,0,2.1-0.6,2.6-1.6l4.5-8.9 c0.3-0.5,0.3-1.2,0-1.7c-0.3-0.5-0.9-0.8-1.5-0.8h-44c-1,0-2,0.6-2.6,1.4l-4.8,9.4c-0.5,0.9-0.6,2-0.1,2.9l36.8,72.6 c0.5,1,1.5,1.6,2.6,1.6h7.9c1.1,0,2.1-0.6,2.6-1.6l17.8-35c0.3-0.5,0.3-1.2,0-1.7C224.9,50.6,224.3,50.3,223.7,50.3"
      />
      <path
        className="primary"
        d="M805.7,1.2H794c-0.8,0-1.5,0.4-1.8,1.1l-28.8,57.3L734.5,2.3c-0.4-0.7-1.1-1.1-1.8-1.1h-11.9 c-1.1,0-2.1,0.9-2.1,2.1v84.1c0,1.1,0.9,2.1,2.1,2.1h9.1c1.1,0,2.1-0.9,2.1-2.1V26.1l26.6,53.2c0.4,0.7,1.1,1.1,1.9,1.1h5.8 c0.8,0,1.5-0.4,1.8-1.1l26.6-53v60.9c0,1.1,0.9,2.1,2.1,2.1h0.2h8.9h0c1.1,0,2.1-0.9,2.1-2.1V26.1V3.2 C807.8,2.1,806.9,1.2,805.7,1.2"
      />
    </svg>
  </SVGContainer>
)

export default SevenroomsLogo
