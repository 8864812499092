import React from 'react'
import styled from 'styled-components'

const ModalScene = styled.div`
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1072;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  outline: 0;
  -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
  display: ${props => (props.show ? 'block' : 'none')};
`

const ModalDialog = styled.div`
  position: relative;
  width: auto;
  pointer-events: none;
  display: flex;
  top: 0px;
  right: 0px;
  left: 0px;
  min-height: 100%;
  opacity: 1;
  background: rgba(26, 25, 25, 0.901961);
`

const ModalContent = styled.div`
  width: ${props => props.width}px;
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background: #fff;
  background-clip: padding-box;
  height: 100vh;
  margin: 40px auto 0;
  transform: translate3d(0px, 0px, 0px);
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 0px 3px;
  opacity: 1;
`

const ModalBody = styled.div`
  transform: translate3d(0px, 0px, 0px);
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 0px 3px;
  opacity: 1;
`

const CloseModalButton = styled.button`
  position: absolute;
  top: -44px;
  right: 0px;
  width: 40px;
  border: 0;
  cursor: pointer;
  line-height: 44px;
  font-size: 36px;
  font-weight: 200;
  font-family: sans-serif;
  text-align: right;
  color: rgb(255, 255, 255);
  background-color: transparent;
`

const WidgetIframe = styled.iframe`
  height: 90vh;
  width: 100%;
  border: none;
  background-color: transparent;
`

class BookingModal extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      containerWidth: null,
    }

    this.lastFocusEl = null
    this.isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
    this.resizeFlag = true
    this._resizeHandler()
  }

  _escapeKeyListener = event => {
    if (event.keyCode === 27 /* Escape */) {
      this.props.toggleBookingModal()
    }
  }

  _setWidgetWidth = () => {
    let containerWidth = Math.max(300, Math.min(700, document.body.clientWidth - 20))
    if ((document.body.clientWidth - containerWidth) % 2 !== 0) {
      containerWidth -= 1
    }
    if (containerWidth != this.state.containerWidth) {
      this.setState({ containerWidth })
    }
    this.resizeFlag = !this.resizeFlag
  }

  _resizeHandler = event => {
    if (!this.resizeFlag) {
      return
    }
    if (typeof requestAnimationFrame === 'undefined') {
      this._setWidgetWidth()
    } else {
      requestAnimationFrame(this._setWidgetWidth)
    }
    this.resizeFlag = !this.resizeFlag
  }

  componentDidUpdate(prevProps) {
    // lock the body to prevent scrolling when we show the modal
    if (this.props.show !== prevProps.show) {
      if (this.props.show) {
        document.body.style.overflow = 'hidden'
        document.body.style.position = 'fixed'
        document.addEventListener('keydown', this._escapeKeyListener)
        this.lastFocusEl = document.activeElement
        document.getElementById(this.props.iframeURL).focus()
      } else {
        document.body.style.overflow = 'auto'
        document.body.style.position = 'relative'
        document.removeEventListener('keydown', this._escapeKeyListener)
        this.lastFocusEl.focus()
        this.lastFocusEl = null
      }
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this._resizeHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeHandler)
  }

  render() {
    const { iframeURL, show, toggleBookingModal } = this.props

    return (
      <ModalScene show={show}>
        <ModalDialog>
          <ModalContent width={this.state.containerWidth}>
            <CloseModalButton id={`${iframeURL}`} onClick={toggleBookingModal} aria-label="close modal">
              ×
            </CloseModalButton>
            <ModalBody>
              <WidgetIframe src={iframeURL} />
            </ModalBody>
          </ModalContent>
        </ModalDialog>
      </ModalScene>
    )
  }
}

export default BookingModal
