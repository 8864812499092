import React from 'react'
import styled, { css } from 'styled-components'
import { PLACEHOLDER_HERO_URL } from '../../reducers/offersDirectory'
import { screenSizes } from '../../utils/responsive'

const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 480px;
  width: 100%;
  overflow: hidden;

  ${props =>
    props.blurred &&
    css`
      height: 725px;
      position: absolute;

      @media (max-width: ${screenSizes.tiny}px) {
        display: none;
      }
    `}

  @media (max-width: ${screenSizes.maxDetails}px) {
    height: 240px;
  }
`

const URL_BASE = '/.h/download'

const HeroImage = styled.div`
  max-width: ${screenSizes.desktop}px;
  width: 100%;
  height: 480px;
  background-color: #f4f4f5;
  background-position: 50%;
  background-size: cover;
  background-image: ${props => `url('${props.defaultUrl}')`};

  ${props =>
    props.blurred &&
    css`
      height: 725px;
      filter: blur(25px);
      background-position: center;
      background-repeat: no-repeat;
    `};

  ${props =>
    props.photoDict &&
    css`
      @media (max-width: ${screenSizes.tiny}px) {
        background-image: ${props => `url('${URL_BASE}/${props.photoDict.medium}')`};
      }

      @media (max-width: ${props => props.photoDict.large_width}px) {
        background-image: ${props => `url('${URL_BASE}/${props.photoDict.large}')`};
      }

      @media (min-width: ${props => props.photoDict.large_width}px) {
        background-image: ${props => `url('${URL_BASE}/${props.photoDict.mega}')`};
      }
    `}

  @media (max-width: ${screenSizes.maxDetails}px) {
    height: 240px;
  }
`

export const SmallHero = styled.img`
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  height: 365px;
  object-fit: cover;

  @media (max-width: ${screenSizes.maxDetails}px) {
    width: 100%;
    max-width: 100%;
    height: 200px;
  }
`

const Hero = ({ image, blurred }) => (
  <HeroContainer blurred={blurred}>
    <HeroImage photoDict={image.photoDict} blurred={blurred} defaultUrl={image.url} />
  </HeroContainer>
)

export default Hero
