import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Carousel from 'nuka-carousel'
import React from 'react'
import styled, { css } from 'styled-components'
import { accessibleComponentWrapper } from 'svr/common/A11y'
import { screenSizes } from '../../utils/responsive'
import { Flex } from '@sevenrooms/core/ui-kit/layout'

const IconComponent = props => <Icon role="button" tabIndex="0" {...props} />
const AccessibleIconComponent = accessibleComponentWrapper(IconComponent, false)

const CarouselContainer = styled.div`
  width: ${screenSizes.maxDetails}px;
  height: ${screenSizes.carouselHeight}px;

  ${props =>
    props.variation === '2020' &&
    css`
      display: flex;
      align-self: center;
      width: ${screenSizes.twentytwenty.carousel}px;
    `};

  @media (max-width: ${screenSizes.desktop}px) {
    width: 100%;
    height: auto;

    .slider-frame {
      height: auto !important;
    }

    .slider-list {
      width: auto !important;
    }
  }
`

const CarouselImageWrapper = styled(Flex)`
  background-color: #f7f7f7;
  height: ${screenSizes.carouselHeight}px;

  @media (max-width: ${screenSizes.desktop}px) {
    width: 100% !important;
    height: 100% !important;
  }
`

const CarouselImage = styled.img`
  max-width: 100%;
  max-height: ${screenSizes.carouselHeight}px;
  align-self: center;

  @media (max-width: ${screenSizes.desktop}px) {
    width: 100% !important;
  }
`

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 ${props => props.theme.gutter.double};
  color: white;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }

  svg {
    height: 32px !important;
    width: 32px !important;
  }
`

class CustomCarousel extends React.PureComponent {
  render() {
    const { images = [], variation = null } = this.props

    return (
      <CarouselContainer variation={variation}>
        <Carousel
          heightMode="max"
          renderCenterLeftControls={({ previousSlide }) => {
            if (images.length < 2) {
              return null
            }

            return (
              <AccessibleIconComponent onClick={previousSlide} aria-label="previous image">
                <FontAwesomeIcon icon={faChevronLeft} />
              </AccessibleIconComponent>
            )
          }}
          renderCenterRightControls={({ nextSlide }) => {
            if (images.length < 2) {
              return null
            }

            return (
              <AccessibleIconComponent onClick={nextSlide} aria-label="next image">
                <FontAwesomeIcon icon={faChevronRight} />
              </AccessibleIconComponent>
            )
          }}
          renderBottomCenterControls={() => null}
        >
          {images.map((img, index) => (
            <CarouselImageWrapper
              key={img.photoDict.raw}
              width={`${variation === '2020' ? screenSizes.twentytwenty.carousel : screenSizes.maxDetails}px`}
              maxWidth={`${variation === '2020' ? screenSizes.twentytwenty.carousel : screenSizes.maxDetails}px`}
              justifyContent="center"
            >
              <CarouselImage
                variation={variation}
                src={img.default}
                srcSet={img.srcSet}
                aria-label={`Custom Image ${index}`}
                alt={`Custom Image ${index}`}
              />
            </CarouselImageWrapper>
          ))}
        </Carousel>
      </CarouselContainer>
    )
  }
}

export default CustomCarousel
