import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import history from 'mgr/pages/shared/utils/History'
import bookingReducer from './booking'
import experiencesReducer from './experiences'
import offersDirectoryReducer from './offersDirectory'
import venueReducer from './venue'

export default combineReducers({
  app: (state = {}) => state,
  router: connectRouter(history),
  experience: experiencesReducer,
  offersDirectory: offersDirectoryReducer,
  venue: venueReducer,
  booking: bookingReducer,
})
