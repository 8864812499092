import _ from 'lodash'
import * as AnalyticsEvents from 'widget/dining/utils/analyticsEvents'
import * as ActionTypes from '../actions/ActionTypes'
import { convertVenue } from '../utils/venue'
import { transformExperience } from './offersDirectory'

const moreExperiencesAvailable = venues =>
  _.reduce(venues, (accum, venue) => (venue.experiences ? _.concat(accum, venue.experiences) : accum), []).length > 1

export const getInitialExperienceState = (mainVenue, mainExperience, allVenues) => {
  const viewExperience = !_.isEmpty(mainExperience)
  const selectedVenue = viewExperience ? convertVenue(mainVenue) : null
  return {
    selectedVenue,
    selectedExperience: viewExperience ? transformExperience(mainExperience) : null,
    hasMoreExperiences: viewExperience ? moreExperiencesAvailable(allVenues) : false,
  }
}

const experiencesReducer = (state = {}, action) => {
  const offerHandler = new AnalyticsEvents.OfferAnalytics()

  switch (action.type) {
    case ActionTypes.SELECT_EXPERIENCE:
      const hasMoreExperiences = moreExperiencesAvailable(action.venues)
      action.venues.forEach(venue => {
        const exp = _.find(venue.experiences, experience => experience.landingURLKey === action.landingURLKey)
        if (!_.isEmpty(exp)) {
          // eslint-disable-next-line no-param-reassign
          state = {
            selectedVenue: venue,
            selectedExperience: exp,
            hasMoreExperiences,
          }
        }
      })

      offerHandler.selectOffer(state.selectedExperience.name, state.selectedVenue.name)
      return state
    default:
      return state
  }
}

export default experiencesReducer
