import React from 'react'
import styled, { css } from 'styled-components'
import { screenSizes, LimitedContainer } from '../../utils/responsive'

export const TemplateContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
  min-height: 100%;
  background: ${props => props.theme.primaryBackGroundColor};

  ${props =>
    props.variation === '2020' &&
    css`
      background: #f8f7fa;
    `}

  color: ${props => props.theme.bodyFontColor};
`

export const Template = styled.div`
  position: absolute;
  width: 100%;
`

export const ContentContainer = styled(LimitedContainer)`
  flex: 1;
`

export const Content = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background: ${props => props.theme.primaryBackGroundColor};

  ${props =>
    props.variation === '2020' &&
    css`
      background: #f8f7fa;
    `};
`
