import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Icon, NoStyleLink } from 'widget/experiences/components/shared/Link'
import { screenSizes } from 'widget/experiences/utils/responsive'
import { getVenueMapUrl } from 'widget/scripts/utils/googleMapLink'
import Social from './Social'

const VenueDetailsContainer = styled.div`
  flex: 1;
  ${props =>
    props.withPadding &&
    css`
      padding-left: ${props => props.theme.gutter.triple};
      padding-bottom: ${props => props.theme.gutter.double};

      @media (max-width: ${screenSizes.desktop}px) {
        text-align: center;
      }
    `}

  ${props =>
    props.variation === '2020' &&
    css`
      @media (max-width: ${screenSizes.desktop}px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
      }
    `}

  @media (max-width: ${screenSizes.desktop}px) {
    padding-left: 0;
  }
`

const VenueDetail = styled.div`
  display: flex;
  padding: ${props => props.theme.gutter.double};

  & > :not(:last-child) {
    margin-right: ${props => props.theme.gutter.standard};
  }

  ${props => props.color && `color: ${props.color};`}

  ${props => props.semibold && 'font-weight: 600;'};

  ${props =>
    !props.clean &&
    css`
      a {
        font-weight: 300;
        text-decoration: none;
        color: ${props => props.theme.menuLinkColor};
        transition: 0.3s opacity;

        &:hover {
          opacity: 0.7;
        }
      }
    `}

  ${props =>
    props.smallMargin &&
    css`
      padding-top: ${props => props.theme.gutter.half};
      padding-bottom: ${props => props.theme.gutter.half};
    `}

  ${props =>
    props.xsMargin &&
    css`
      padding: ${props => props.theme.gutter.half} ${props => props.theme.gutter.double};
    `}

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `}

   ${props =>
    props.hasBorder &&
    css`
      padding-bottom: ${props => props.theme.gutter.double};
      border-bottom: 1px solid ${props => props.theme.borderColor};
    `}


  @media (max-width: ${screenSizes.desktop}px) {
    text-align: center;
    justify-content: center;
  }
`

const ContactIcon = styled(Icon)`
  transform: scale(0.75);
  margin: 0 !important;
`

const VenueDetails = ({ venue, withPadding = true, variation, hasMoreExperiences = false, isPublic, clientId }) => {
  const { name, addressStreet, addressCity, phone, email, website } = venue.details

  const phoneLink = `tel:${phone}`
  const emailLink = `mailto:${email}`

  return (
    <VenueDetailsContainer variation={variation} withPadding={withPadding} className="venue-details">
      {variation === '2020' && <VenueDetail color="black">Location</VenueDetail>}
      <NoStyleLink
        href={getVenueMapUrl({
          name: venue.details.name,
          address: venue.details.addressStreet,
          city: venue.details.addressCity,
          state: venue.details.addressState,
          postalCode: venue.details.addressPostalCode,
          gmapsLink: venue.details.gmapsLink,
        })}
        target="_blank"
        rel="noreferrer noopener"
      >
        {name && (
          <VenueDetail xsMargin={variation === '2020'} smallMargin>
            {name}
          </VenueDetail>
        )}
        {addressStreet && (
          <VenueDetail xsMargin={variation === '2020'} smallMargin>
            {addressStreet}
          </VenueDetail>
        )}
        {addressCity && (
          <VenueDetail smallMargin xsMargin={variation === '2020'} hasBorder={variation !== '2020'}>
            {addressCity}
          </VenueDetail>
        )}
      </NoStyleLink>
      {variation !== '2020' ? (
        <div>
          {phone && (
            <VenueDetail xsMargin={variation === '2020'} hasBorder={variation !== '2020'}>
              <a href={phoneLink}>{phone}</a>
            </VenueDetail>
          )}
          {email && (
            <VenueDetail xsMargin={variation === '2020'} hasBorder={variation !== '2020'}>
              <a href={emailLink}>{email}</a>
            </VenueDetail>
          )}
          {website && (
            <VenueDetail xsMargin={variation === '2020'} hasBorder={variation !== '2020'}>
              <a href={website} target="_blank" rel="noreferrer">
                {website}
              </a>
            </VenueDetail>
          )}
          {hasMoreExperiences && isPublic && (
            <VenueDetail xsMargin={false} hasBorder={false}>
              <Link to={`/experiences/${venue.urlKey}${clientId ? `?client_id=${clientId}` : ''}`}>More Offers</Link>
            </VenueDetail>
          )}
        </div>
      ) : (
        <VenueDetail clean>
          <ContactIcon href={phoneLink} target="_blank" aria-label="phone number">
            <FontAwesomeIcon icon={faPhone} />
          </ContactIcon>
          <ContactIcon href={emailLink} target="_blank" aria-label="email address">
            <FontAwesomeIcon icon={faEnvelope} />
          </ContactIcon>
        </VenueDetail>
      )}

      {variation !== '2020' && <Social venue={venue} />}
    </VenueDetailsContainer>
  )
}

export default VenueDetails
