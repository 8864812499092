import React from 'react'
import { SocialIcon } from 'react-social-icons'
import styled, { css } from 'styled-components'
import SevenroomsLogo from 'svr/common/SevenroomsLogo'
import { screenSizes, LimitedContainer } from '../../utils/responsive'

const FooterContainer = styled.footer`
  width: 100%;
  background: ${props => props.theme.footerBackGroundColor};
  color: ${props => props.theme.bodyFontColor};
  padding: 15px 0;
  font-size: 15px;
`

const InnerContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0 0 auto;
  margin: auto;
`

const SectionContainer = styled.div`
  width: 50%;
  background: ${props => props.theme.footerBackGroundColor};
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.alignRight ? 'flex-end' : 'flex-start')};
  padding: ${props => (props.alignRight ? `0 ${props.theme.gutter.double} 0 0` : `0 0 0 ${props.theme.gutter.double}`)};
`

const FooterDetail = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (max-width: ${screenSizes.maxDetails}px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
  }
`
const VenueDetail = styled.div`
  display: flex;
  padding: 4px 0;
`

const ContactDetailsContainer = styled.div`
  width: 100%;
  text-align: right;
  @media (min-width: ${screenSizes.maxDetails}px) {
    & div:first-child::after {
      content: '';
      margin-right: ${props => props.theme.gutter.standard};
    }
    & div:last-child::before {
      content: '';
      margin-left: ${props => props.theme.gutter.standard};
    }
  }
`

const ContactDetail = styled.div`
  display: inline-block;
  padding: 4px 0;
  @media (max-width: ${screenSizes.maxDetails}px) {
    width: 100%;
  }
`

const SocialIcons = styled.div`
  display: flex;
  padding: 0 0 4px 0;
  @media (max-width: ${screenSizes.desktop}px) {
    justify-content: center;
  }
`

const PoweredBy = styled.span`
  font-size: ${props => props.theme.fontSize.xsmall};
  margin-right: ${props => props.theme.gutter.standard};
  color: black;
  margin-top: 4px;
`

const isStringEmpty = str => !str || str.trim().length === 0

const Footer = ({ venue }) => {
  const { name, addressStreet, addressCity, phone, email, website, instagramLink, facebookLink, twitterLink } = venue.details
  const hasSocial = instagramLink || facebookLink || twitterLink
  const contactText = [phone, email].filter(Boolean).join(' | ')
  const iconStyle = { height: 22, width: 22, margin: '0 0 0 16px' }

  return (
    <FooterContainer id="experiences-list-footer">
      <InnerContainer>
        <SectionContainer>
          {name && <VenueDetail smallMargin>{name}</VenueDetail>}
          {addressStreet && <VenueDetail smallMargin>{addressStreet}</VenueDetail>}
          {addressCity && <VenueDetail smallMargin>{addressCity}</VenueDetail>}
          <VenueDetail>
            &copy; {new Date().getFullYear()} {name}
          </VenueDetail>
        </SectionContainer>
        <SectionContainer alignRight>
          {hasSocial && (
            <SocialIcons>
              {instagramLink && <SocialIcon style={iconStyle} target="_blank" url={instagramLink} />}
              {facebookLink && <SocialIcon style={iconStyle} target="_blank" url={facebookLink} />}
              {twitterLink && <SocialIcon style={iconStyle} target="_blank" url={twitterLink} />}
            </SocialIcons>
          )}
          <ContactDetailsContainer>
            <ContactDetail>{phone}</ContactDetail>
            {!isStringEmpty(phone) && !isStringEmpty(email) ? '|' : ''}
            <ContactDetail>{email}</ContactDetail>
          </ContactDetailsContainer>
          <FooterDetail>
            <PoweredBy>powered by</PoweredBy>
            <SevenroomsLogo />
          </FooterDetail>
        </SectionContainer>
      </InnerContainer>
    </FooterContainer>
  )
}

export default Footer
