import { TOGGLE_EXPERIENCE_BOOK, TOGGLE_EXPERIENCE_MODAL, SELECT_EXPERIENCE } from 'widget/experiences/actions/ActionTypes'

export const toggleBookingModal = () => (dispatch, getState) => {
  dispatch({ type: TOGGLE_EXPERIENCE_BOOK })
}

export const toggleExperienceBookingModal = (experienceId = null) => ({
  type: TOGGLE_EXPERIENCE_MODAL,
  experienceId,
})

export const selectExperience = landingURLKey => (dispatch, getState) =>
  dispatch({
    type: SELECT_EXPERIENCE,
    landingURLKey,
    venues: getState().offersDirectory.venues,
  })
