import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import BookingModal from 'widget/experiences/components/shared/BookingModal'
import { Template, Content, TemplateContainer } from 'widget/experiences/components/shared/Layout'
import { MenuLink, Button, Detail } from 'widget/experiences/components/shared/Link'
import Carousel from 'widget/experiences/components/single-experience/Carousel'
import Footer from 'widget/experiences/components/single-experience/Footer'
import Header from 'widget/experiences/components/single-experience/Header'
import Hero, { SmallHero } from 'widget/experiences/components/single-experience/Hero'
import Social from 'widget/experiences/components/single-experience/Social'
import VenueDetails from 'widget/experiences/components/single-experience/VenueDetails'
import { PLACEHOLDER_HERO_URL } from 'widget/experiences/reducers/offersDirectory'
import { screenSizes, LimitedContainer } from 'widget/experiences/utils/responsive'

const DetailsContainer = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: ${screenSizes.maxDetails}px) {
    flex-direction: column;
  }
`

const SimpleDetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${props => props.theme.gutter.double};
  justify-content: space-between;
  background: #f5f3ee;

  @media (max-width: ${screenSizes.maxDetails}px) {
    align-items: center;
  }
`

const Name = styled.h1`
  font-weight: 500;
  font-size: 1.5em;
  margin-top: ${props => props.theme.gutter.triple};
  margin-bottom: ${props => props.theme.gutter.double};
`

const Cost = styled.label`
  font-weight: 400;
  font-size: 16px;
`

const BookNowContainer = styled.div`
  display: flex;
  padding: ${props => props.theme.gutter.double};
  border-bottom: 1px solid ${props => props.theme.borderColor};
  justify-content: space-between;

  @media (max-width: ${screenSizes.desktop}px) {
    justify-content: center;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const FixedButtonContainer = styled.div`
  display: none;
  justify-content: center;
  height: 70px;

  @media (max-width: 768px) {
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 1;
    left: 0;
    display: flex;

    button {
      width: 100%;
      justify-content: center;
    }
  }
`

const SubContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`

const DescriptionContainer = styled.div`
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  padding: ${props => props.theme.gutter.double};

  @media (max-width: ${screenSizes.desktop}px) {
    flex: 1;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    text-align: center;
  }
`

const SpecificDetailsContainer = styled.div`
  display: flex;
  padding-top: ${props => props.theme.gutter.triple};

  @media (max-width: ${screenSizes.desktop}px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`

const FlexMenuContainer = styled.div`
  flex: 1;
  margin-bottom: ${props => props.theme.gutter.double};
`

const FlexMenuLink = styled(MenuLink)`
  font-size: 1em;
`

const StyledLink = styled(Link)`
  font-weight: 300;
  text-decoration: none;
  color: ${props => props.theme.menuLinkColor};
  transition: 0.3s opacity;
  align-self: center;

  &: hover {
    opacity: 0.7;
  }

  @media (max-width: ${screenSizes.desktop}px) {
    display: none;
  }
`

const ExperienceDetails = ({ experience }) => (
  <SimpleDetailsContainer>
    <Name data-test="sr-experiences-template-two-name">{experience.name}</Name>
    {experience.menuURL && (
      <FlexMenuContainer>
        <FlexMenuLink href={experience.menuURL} target="_blank">
          View Menu
        </FlexMenuLink>
      </FlexMenuContainer>
    )}
    <Cost>{experience.priceDescription}</Cost>
  </SimpleDetailsContainer>
)

const SmallDetail = styled.div`
  padding-bottom: ${props => props.theme.gutter.standard};
  font-size: 14px;

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `};
`

const SummaryDetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${props => props.theme.gutter.triple} 0;
`

const SummaryDetails = ({ experience, venue }) => (
  <SummaryDetailsContainer>
    <SmallDetail bold>{experience.name}</SmallDetail>
    <SmallDetail>at</SmallDetail>
    <SmallDetail bold>{venue.name}</SmallDetail>
    <SmallDetail>{`${venue.details.addressStreet}, ${venue.details.addressCity}`}</SmallDetail>
  </SummaryDetailsContainer>
)

const ButtonWithIcon = styled(Button)`
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  padding: 12px 116px;

  & > *:not(:last-child) {
    margin-right: ${props => props.theme.gutter.double};
  }

  @media (max-width: ${screenSizes.maxDetails}px) {
    padding: 12px 64px;
    height: 70px;
  }

  @media (max-width: ${screenSizes.tiny}px) {
    padding: 12px 32px;
  }
`

const TemplateTwo = ({
  venue,
  experience,
  toggleBookingModal,
  showBookingModal,
  iframeURL,
  hasMoreExperiences,
  isPublic,
  clientId,
  baseVenueUrlKey,
  queryStringVenues,
  groupVenue,
  logo,
}) => (
  <TemplateContainer variation="2020">
    <Template variation="2020">
      <Header
        venue={venue}
        wide
        onBookNowClick={toggleBookingModal}
        logo={logo}
        hasMoreExperiences={hasMoreExperiences}
        isPublic={isPublic}
        clientId={clientId}
        baseVenueUrlKey={baseVenueUrlKey}
        queryStringVenues={queryStringVenues}
        groupVenue={groupVenue}
        tracking={experience.tracking}
      />
      <Content variation="2020">
        <Hero blurred image={experience.heroImage} alt="Hero Image" />
        <LimitedContainer overlayed>
          <SubContentContainer>
            <DetailsContainer>
              <SmallHero src={experience.heroImage.default || PLACEHOLDER_HERO_URL} srcSet={experience.heroImage.srcSet} alt="Hero Image" />
              <ExperienceDetails experience={experience} />
            </DetailsContainer>
            <BookNowContainer>
              {hasMoreExperiences && isPublic ? (
                <StyledLink
                  to={`/experiences/${baseVenueUrlKey}${clientId ? `?client_id=${clientId}` : ''}${
                    queryStringVenues ? `?venues=${queryStringVenues}&group_venue=${groupVenue}` : ''
                  }`}
                >
                  More Offers
                </StyledLink>
              ) : (
                <div />
              )}
              <ButtonWithIcon onClick={toggleBookingModal} data-test="sr-experiences-template-two-book-now">
                <FontAwesomeIcon icon={faCalendar} />
                <div>Book Now</div>
              </ButtonWithIcon>
            </BookNowContainer>
            <SpecificDetailsContainer>
              <DescriptionContainer>
                <Detail color="black" smallText extraMargin>
                  {experience.title || 'Description'}
                </Detail>
                <Detail color="rgb(111, 114, 135)" reset smallText dangerouslySetInnerHTML={{ __html: experience.description }} />
              </DescriptionContainer>
              <VenueDetails variation="2020" venue={venue} isPublic={isPublic} />
            </SpecificDetailsContainer>
            <Social variation="2020" withBorder label="Share With Friends" venue={venue} />
            {experience.additionalImages.length > 0 && <Carousel variation="2020" images={experience.additionalImages} />}
            <SummaryDetails venue={venue} experience={experience} />
          </SubContentContainer>
        </LimitedContainer>
      </Content>
      <Footer variation="2020" venueGroupName={venue.name} />
      <BookingModal toggleBookingModal={toggleBookingModal} show={showBookingModal} iframeURL={iframeURL} />
      <FixedButtonContainer>
        <ButtonWithIcon onClick={toggleBookingModal} data-test="sr-experiences-template-two-book-now">
          <FontAwesomeIcon icon={faCalendar} />
          <div>Book Now</div>
        </ButtonWithIcon>
      </FixedButtonContainer>
    </Template>
  </TemplateContainer>
)

export default TemplateTwo
