import React from 'react'
import styled, { css } from 'styled-components'
import { screenSizes } from '../../utils/responsive'
import { Link, MenuLink, Button, Detail } from '../shared/Link'
import Carousel from './Carousel'
import VenueDetails from './VenueDetails'

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${props => props.theme.gutter.double} ${props => props.theme.gutter.standard};

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    padding: ${props => props.theme.gutter.double};
    flex-direction: column;
  }
`

const SubHeader = styled.h1`
  font-size: ${props => props.theme.fontSize.largeSubheader};
  margin: ${props => props.theme.gutter.standard} 0 ${props => props.theme.gutter.triple} 0;
  font-weight: 300;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  color: ${props => props.theme.subheaderTextColor};
`

const AdditionalContentContainer = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: ${screenSizes.desktop}px) {
    justify-content: center;
    padding: ${props => props.theme.gutter.double};
    flex-direction: column;
  }

  @media (max-width: ${screenSizes.desktop}px) {
    align-items: center;
  }
`

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${props => props.theme.fontSize.subheader};
  padding: ${props => props.theme.gutter.standard} 0;
  border-top: 1px solid ${props => props.theme.borderColor};
  border-bottom: 1px solid ${props => props.theme.borderColor};
  margin: ${props => props.theme.gutter.double} 0;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`

const PriceDetails = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding-right: 62px;

  @media (max-width: 768px) {
    margin-bottom: ${props => props.theme.gutter.double};
  }
`

const Price = styled.div`
  padding-right: ${props => props.theme.gutter.standard};
`

const Cost = styled.span`
  font-weight: 300;
`

const BookNowContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 1;
    left: 0;
    border-bottom: 0px;
    -webkit-backface-visibility: hidden;

    button {
      width: 100%;
      height: 70px;
    }
  }
`

// TODO break this up possibly
const Details = ({ venue, experience, onBookNowClick, hasMoreExperiences, clientId }) => {
  const defaultTitle = `Come join us at ${venue.name}`

  return (
    <DetailsContainer data-test="offer-details">
      <SubHeader>{experience.title || defaultTitle}</SubHeader>
      <Detail bold>{experience.name}</Detail>
      <Detail reset smallText dangerouslySetInnerHTML={{ __html: experience.description }} />
      <Section>
        <PriceDetails>
          <Price>
            Price: <Cost>{experience.priceDescription}</Cost>
          </Price>
          {experience.menuURL && (
            <MenuLink href={experience.menuURL} target="_blank">
              View Menu
            </MenuLink>
          )}
        </PriceDetails>
        <BookNowContainer>
          <Button onClick={onBookNowClick} data-test="offers-book-now-btn">
            Book Now
          </Button>
        </BookNowContainer>
      </Section>
      <AdditionalContentContainer>
        <Carousel images={experience.additionalImages} />
        <VenueDetails withPadding venue={venue} hasMoreExperiences={hasMoreExperiences} clientId={clientId} />
      </AdditionalContentContainer>
    </DetailsContainer>
  )
}

export default Details
