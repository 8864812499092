import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import VenueInfoContainer from 'widget/experiences/containers/VenueInfoContainer'
import { screenSizes } from '../../utils/responsive'
import ExperienceCards from './ExperienceCards'
import MidSection from './MidSection'

export const InnerContent = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.primaryBackGroundColor};
  
  @media (min-width: ${screenSizes.large}px) {
    width: ${screenSizes.largeMax}px;
  }

  @media (max-width: ${screenSizes.large}px) {
    width: ${screenSizes.largeMin}px;
  }

  @media (max-width: ${screenSizes.maxMobile}px) {
    max-width: 100%;
    justify-content: center;

  }

  @media (max-width: ${screenSizes.iphone6plus}px) {
      div {
        width: 100%;
      }
    }
  }
`

const MultiVenueContainer = styled(InnerContent)`
  margin-bottom: 10;
`

const MultiVenue = ({ base_venue, venues, clientId, selectExperience, cardContainerWidth, queryStringVenues, groupVenue }) => {
  const numOffers = _.reduce(venues, (accum, venue) => accum + venue.experiences.length, 0)
  return (
    <InnerContent>
      <MidSection numOffers={numOffers} headerText={base_venue.name} showDividerLine />
      {_.map(venues, venue => {
        if (venue.experiences.length > 0) {
          return (
            <MultiVenueContainer key={venue.key}>
              <VenueInfoContainer venue={venue} />
              <ExperienceCards
                venue={venue}
                experiences={venue.experiences}
                clientId={clientId}
                selectExperience={selectExperience}
                cardContainerWidth={cardContainerWidth}
                queryStringVenues={queryStringVenues}
                groupVenue={groupVenue}
              />
            </MultiVenueContainer>
          )
        }
      })}
    </InnerContent>
  )
}

export default MultiVenue
