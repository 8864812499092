import React from 'react'
import styled, { css } from 'styled-components'
import { Button } from '../shared/Link'

const Screen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  z-index: 10;
`

const Container = styled.div`
  position: absolute;
  top: 6%;
  left: 10%;
  right: 10%;
  height: 200px;
  z-index: 100;
  background: white;
`

const Header = styled.div`
  height: 50px;
  width: 100%;
  background: green;
  position: relative;
  color: white;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  font-weight: 400;
  background: ${props => props.theme.buttonBackgroundColor};
`

const Body = styled.div`
  position: relative;
  height: 150px;
  width: 100%;
`

const Text = styled.div`
  width: 100%;
  height: 80px;
  text-align: center;
  line-height: 80px;
`

const ButtonLayer = styled.div`
  height: 70px;
  display: flex;
  justify-content: center;
  padding: 15px;
`

const CustomButton = css`
  font-weight: 300;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  margin: 0 20px;
  font-size: 17px;
`

class InactiveModal extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleResWidgetNavigation = this.handleNavigation.bind(this, this.props.reservationsWidgetURL)
    this.handleExperiencesListNavigation = this.handleNavigation.bind(this, this.props.experiencesListURL)
  }

  handleNavigation = url => (window.location.href = url)

  render() {
    const { additionalOffers, reservationsWidgetURL, experiencesListURL } = this.props
    return (
      <Screen>
        <Container>
          <Header>Offer not available</Header>
          <Body>
            <Text>Unfortunately, this offer is no longer available.</Text>
            <ButtonLayer>
              <Button customButton={CustomButton} onClick={this.handleResWidgetNavigation}>
                Reservations
              </Button>
              <Button customButton={CustomButton} onClick={this.handleExperiencesListNavigation}>
                View all offers
              </Button>
            </ButtonLayer>
          </Body>
        </Container>
      </Screen>
    )
  }
}

export default InactiveModal
